import newDayjs from "dayjs";
export function utcToLocal(time = "", type = -1) {
  if (time == "") {
    return newDayjs();
  }
  // utcToLocal().format('YYYY-MM-DD HH:mm:ss')
  return newDayjs(time).add(new Date().getTimezoneOffset() * type, "minute");
}

export let dayjs = newDayjs;
