<template>
  <div>
    <div class="apply_form_all">
      <form-bar :whoApply="whoApply" name=".firstName"></form-bar>

      <div class="form_css " :class="system.isPhone ? 'wapfont12' : 'font14'">
        <div class="hang header">
          <div class="kuai">
            {{ $t("applyAgent." + whoApply + ".msgType") }}
          </div>
          <div class="kuai">
            {{ $t("applyAgent." + whoApply + ".msgInfo") }}
          </div>
          <div class="kuai">
            {{ $t("applyAgent." + whoApply + ".msgTime") }}
          </div>
        </div>

        <div
          v-for="(item, index) in msg"
          :key="index"
          :class="system.isPhone ? 'wap_hang' : 'hang'"
          class=" hang_body"
        >
          <div class="kuai">
            {{ item.n_title }}
          </div>
          <div class="kuai kuuo">
            <!-- :class="item.n_status.length === 1 ? '' : ''" -->
            <div v-for="(item2, index2) in item.n_status" :key="index2">
              {{ item2 }}
            </div>
          </div>
          <div class="kuai">
            {{ item.gmtCreate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formBar from "@/views/applyContact/applyAgent/components/formBar";
import { utcToLocal } from "@/utils/dayjs.js";
export default {
  components: {
    formBar
  },
  data() {
    return {
      whoApply: "noticeMsg",
      labelPosition: "right",
      msg: []
    };
  },
  mounted() {
    this.getMsg();
  },
  methods: {
    getMsg() {
      //进行提交
      this.$request("/api/front/userInfoCheck/loadUserInfoCheckList", {
        method: "GET"
      })
        .then(res => {
          if (res.code === 1) {
            res.data.forEach(item => {
              item.gmtCreate = utcToLocal(item.gmtCreate).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              //加入消息内容
              if (item.level > 0 && item.level < 5) {
                item.n_status = this.$t("noticeMsg.status[0]");
              } else {
                item.n_status = this.$t(
                  "noticeMsg.status[" + item.status + "]"
                );
                item.n_status[1] = item.reason || " ";
              }
              //加入消息类型
              if (item.level === 5) {
                item.n_title = `${this.$t("noticeMsg.outLine")}-${this.$t(
                  "noticeMsg.provinceApply"
                )}`;
              } else if (item.level === 6) {
                item.n_title = `${this.$t("noticeMsg.outLine")}-${this.$t(
                  "noticeMsg.cityApply"
                )}`;
              } else if (item.level > 0 && item.level < 5) {
                item.n_title = `${this.$t(
                  "noticeMsg.applyJoinUs[" + item.level + "]"
                )}${this.$t("noticeMsg.leaveMsg")}`;
              } else if (item.kind === "AustraliaCompany") {
                item.n_title = `${this.$t(
                  "noticeMsg.AustraliaCompany"
                )}-${this.$t("noticeMsg.Personal")}`;
              } else if (item.kind === "Personal") {
                item.n_title = `${this.$t("noticeMsg.onLine")}-${this.$t(
                  "noticeMsg.onLinePersonal"
                )}`;
              } else if (item.kind === "ChinaCompany") {
                item.n_title = `${this.$t("noticeMsg.onLine")}-${this.$t(
                  "noticeMsg.ChinaCompany"
                )}`;
              }

              //
              // console.log(
              //   item.status,
              //   item.kind,
              //   item.level,
              //   item.businessLine
              // );
            });
            this.msg = res.data;
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  background-color: rgba(239, 240, 246, 1);
}
.hang {
  display: flex;
  min-height: 58px;
  align-items: center;
  .kuai {
    flex: 1;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .kuai:nth-child(2) {
    flex: 2.5;
    flex-direction: column;
    > li {
      padding: 5px 50px;
      width: 100%;
      text-align: left;
      list-style: none;
      box-sizing: border-box;
    }

    > li:nth-child(2) {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .kuuo {
    padding-top: 20px;
    padding-bottom: 20px;
    > div {
      text-align: left;
      padding: 0 8px;
    }
  }
}
.wap_hang {
  display: flex;
  min-height: 58px;
  align-items: center;
  .kuai {
    flex: 1;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .kuai:nth-child(2) {
    flex: 2.5;
    flex-direction: column;

    > li {
      padding: 5px 10px;
      width: 100%;
      text-align: left;
      list-style: none;
      box-sizing: border-box;
    }
    > li:nth-child(2) {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .kuuo {
    padding-top: 20px;
    padding-bottom: 20px;
    > div {
      text-align: left;
      padding: 0 8px;
    }
  }
}
.hang_body {
  border-bottom: solid 1px rgba(0, 0, 0, 0.06);
  .kuai:nth-child(2) {
    border-left: solid 1px rgba(0, 0, 0, 0.06);
    border-right: solid 1px rgba(0, 0, 0, 0.06);
  }
}
.zanwei {
  height: 60px;
}
.next_but {
  width: 272px;
  height: 53px;
  border-radius: 67px;
  margin-top: 60px;
}
.apply_form_all {
  width: 100%;
  .text_line {
    // padding-top: 18px;
    // padding-bottom: 18px;
  }
  /deep/.form_css {
    border: solid 1px rgba(0, 0, 0, 0.06);
    max-width: 816px;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 36px;
    .el-form-item--mini {
      margin-bottom: 36px;
    }
    .el-textarea__inner.el-textarea__inner {
      height: 100px;
    }
  }
}
</style>
