<template>
  <div :class="system.isPhone ? 'wap_form_bar wapfont18' : 'form_bar'">
    <div v-if="textNone != '0'" class="content">
      {{ $t("applyAgent." + whoApply + name) }}
    </div>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  data() {
    return {
      // whoApply: this.$route.query.whoApply || "personalApply"
    };
  },
  props: ["name", "whoApply", "textNone"],
  computed: {},
  methods: {},
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.form_bar {
  width: auto;
  margin: auto 60px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  .content {
    border-left: solid 4px #1977fb;
    height: 17px;
    line-height: 15px;
    padding-left: 8px;
  }
  margin-bottom: 18px;
  padding-bottom: 24px;
}
.wap_form_bar {
  width: auto;
  margin: auto 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  // border-bottom: solid 2px #1977fb;
  border-bottom: solid 2px #bf9264;
  margin-top: calc(100vw * 2.4 / 37.5);
  padding-bottom: 4px;
}
</style>
